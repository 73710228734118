
body {
  margin: 0px;
  height: 100vh;
  width: 100vw;
  /* font-family: 'Open Sans', sans-serif; */
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disabled-row {
background-color: rgba(0, 0, 0, 0.2);
}

.requested {
  background-color: rgba(0, 255, 94, 0.2);
}

.enabled-row {
  cursor: pointer;
}